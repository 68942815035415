import React from "react";
import { useTranslation } from "react-i18next";
import withTheme from "@material-ui/core/styles/withTheme";
import TestHeader from "../TestHeader";
import { LocationIco } from "../../icons";
import { RenderValues, obfuscateIp } from "../helpers/utils";
import { formatNumber } from "../../../helpers/utils";
import { ILocation, IMediaIp, ITestsResult } from "twillio-tests/core/TestResults";
import { Typography, withStyles } from "@material-ui/core";
import Placeholder from "./UI/location-placeholder.png";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import mapModule from "highcharts/modules/map";
import mapData from "@highcharts/map-collection/custom/world.topo.json";

interface ILocationTestProps {
  mainColor: string;
  testResult?: ITestsResult;
  theme: any;
  knowledgeUrl?: string;
  estimateTime?: number;
  testName?: string;
  showInMap?: boolean;
  config?: any;
  classes?: any;
}

const initialValues = {
  country: "",
  ip: "",
  organization: "",
  city: "",
  coordinates: { latitude: 0, longitude: 0 },
};

const styles = (theme: any): any => ({
  testContainer: {
    position: "relative",
    display: "flex",
    flex: 1,
    height: "100%",
  },
  resultWrapper: {
    padding: "8px 16px",
    borderRadius: "8px",
    position: "absolute",
    zIndex: 10,
    bottom: "16px",
    left: "16px",
    boxShadow: "0px 4px 4px 0px #00000040",
    background: theme.newPalette.location.cardBg,
  },
  resultContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  result: {
    display: "flex",
    alignItems: "center",
    gap: "9px",
  },
  titleColor: {
    color: theme.newPalette.text.primary,
    maxWidth: "120px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  label: {
    width: "50px",
    color: theme.newPalette.text.primary,
    textTransform: "capitalize",
  },
  mediaWrapper: {
    padding: "9px 7px 8px 7px",
    borderRadius: "8px",
    position: "absolute",
    zIndex: 10,
    bottom: "16px",
    left: "16px",
    boxShadow: "0px 4px 4px 0px #00000040",
    background: theme.newPalette.location.cardBg,
  },
  doubleLocationContainer: {
    display: "flex",
    "& > :not(:first-child)": {
      borderRightWidth: "0px",
      borderLeftWidth: "1px",
      borderLeft: `1px solid ${theme.newPalette.other.divider}`,
    },
  },
  mediaContainer: {
    paddingRight: "8px",
  },
  signallingContainer: {
    paddingLeft: "8px",
  },
  doubleLabel: {
    textAlign: "center",
    paddingBottom: "8px",
    color: theme.newPalette.text.primary,
  },
});

mapModule(Highcharts);

const LocationTest = (props: ILocationTestProps) => {
  const { mainColor, testResult, theme, knowledgeUrl, estimateTime, testName, showInMap, config, classes } = props;
  const locationTest: ILocation = testResult && testResult.location ? testResult.location : initialValues;

  const { coordinates } = locationTest;
  let { country, ip, organization, city, error } = locationTest;
  if (city === undefined) {
    city = "error";
  } else if (country === undefined) {
    country = "error";
  } else if (ip === undefined) {
    ip = "error";
  } else if (organization === undefined) {
    organization = "error";
  }

  function calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number) {
    var R = 6371;
    var dLat = ((lat2 - lat1) * Math.PI) / 180;
    var dLon = ((lon2 - lon1) * Math.PI) / 180;
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }

  const distance =
    testResult?.mediaIp?.coordinates?.latitude !== undefined && testResult?.mediaIp?.coordinates?.longitude !== undefined
      ? calculateDistance(
          coordinates.latitude,
          coordinates.longitude,
          Number(testResult.mediaIp.coordinates.latitude),
          Number(testResult.mediaIp.coordinates.longitude)
        )
      : 0;

  const zoomLevel = distance === 0 ? 2.5 : distance < 10 ? 8 : distance < 20 ? 4 : 2.5;

  const defaultProps = {
    mainColor,
    color: theme.customColors.successColor,
    formatting: formatNumber,
  };
  const colorTypes = {
    errorColor: theme.customColors.errorColor,
    warningColor: theme.customColors.warningColor,
    successColor: theme.customColors.successColor,
  };

  const isVPN = locationTest.traits?.isAnonymous || locationTest.traits?.isPublicProxy || locationTest.traits?.isTorExitNode;
  const isHosted = locationTest.traits?.isHostingProvider && locationTest.traits.userType === "hosting";
  const ipColor = isVPN ? colorTypes.errorColor : isHosted ? colorTypes.warningColor : colorTypes.successColor;

  const { t } = useTranslation(["common", "tests"]);
  const mapOptions = {
    chart: {
      map: "countries/ie/ie-all",
      margin: [-30, 0, 0, 0],
      backgroundColor: "transparent",
      plotBackgroundColor: "transparent",
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    mapNavigation: {
      enabled: false,
    },
    legend: { enabled: false },
    tooltip: {
      headerFormat: "",
      pointFormat: "<b>{point.freq}</b><br><b>{point.keyword}</b>                      <br>lat: {point.lat}, lon: {point.lon}",
    },
    plotOptions: {
      mappoint: {
        keys: ["y", "keyword", "lat", "lon"],
        marker: {
          lineWidth: 1,
          lineColor: "#000",
          symbol: "mapmarker",
          radius: 8,
        },
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "Basemap",
        mapData: mapData,
        borderColor: theme.newPalette.location.border,
        nullColor: theme.newPalette.location.null,
        showInLegend: false,
      },
      {
        type: "mappoint",
        color: "#D32F2F",
        data: [{ z: 10, keyword: city, lat: coordinates?.latitude, lon: coordinates?.longitude }],
        cursor: "pointer",
      },
      {
        type: "mappoint",
        color: "#D32F2F",
        data:
          testResult?.mediaIp?.coordinates?.latitude !== undefined && testResult?.mediaIp?.coordinates?.longitude !== undefined
            ? [
                {
                  z: 10,
                  keyword: testResult.mediaIp.city,
                  lat: testResult.mediaIp.coordinates.latitude,
                  lon: testResult.mediaIp.coordinates.longitude,
                },
              ]
            : [],
        cursor: "pointer",
      },
    ],
    mapView: {
      center: [coordinates?.longitude, coordinates?.latitude],
      zoom: zoomLevel,
    },
  };

  return (
    <TestHeader
      icon={<LocationIco />}
      title={t("location")}
      tooltip={t("tests:location.tooltip")}
      showInMap={error ? false : showInMap}
      location={{ coordinates }}
      error={error}
      knowledgeUrl={knowledgeUrl}
      estimateTime={estimateTime}
      testName={testName}
      badge={isVPN ? "VPN" : isHosted ? "HOSTED" : ""}
    >
      {config?.useNewWidgetDesign ? (
        <div className={classes.testContainer}>
          <HighchartsReact highcharts={Highcharts} constructorType={"mapChart"} options={mapOptions} />

          {testResult && testResult.mediaIp !== undefined && !testResult.mediaIp.error && Object.keys(testResult.mediaIp).length > 0 ? (
            <div className={classes.mediaWrapper}>
              <div className={classes.doubleLocationContainer}>
                <div className={classes.mediaContainer}>
                  <Typography variant="body1" className={classes.doubleLabel}>
                    Media
                  </Typography>
                  <div className={classes.resultContainer}>
                    <div className={classes.result} id="LT-r-IPMedia">
                      <Typography variant="caption" className={classes.label}>
                        IP
                      </Typography>
                      <Typography variant="h4" className={classes.titleColor} id="LT-c-IPMedia">
                        {config?.Location?.ip === "obfuscate" && config?.options.debug ? obfuscateIp(testResult.mediaIp?.ip) : testResult.mediaIp?.ip}
                      </Typography>
                    </div>
                    <div className={classes.result} id="LT-r-cityMedia">
                      <Typography variant="caption" className={classes.label}>
                        {t("city")}
                      </Typography>
                      <Typography variant="h4" className={classes.titleColor} id="LT-c-cityMedia">
                        {testResult.mediaIp?.city}
                      </Typography>
                    </div>
                    <div className={classes.result} id="LT-r-countryMedia">
                      <Typography variant="caption" className={classes.label}>
                        {t("country")}
                      </Typography>
                      <Typography variant="h4" className={classes.titleColor} id="LT-c-countryMedia">
                        {testResult.mediaIp?.country}
                      </Typography>
                    </div>
                    <div className={classes.result} id="LT-r-orgMedia">
                      <Typography variant="caption" className={classes.label}>
                        {t("org")}
                      </Typography>
                      <Typography variant="h4" className={classes.titleColor} id="LT-c-orgMedia">
                        {testResult.mediaIp?.organization}
                      </Typography>
                    </div>
                  </div>
                </div>
                <div className={classes.signallingContainer}>
                  <Typography variant="body1" className={classes.doubleLabel}>
                    Signalling
                  </Typography>
                  <div className={classes.resultContainer}>
                    <div className={classes.result} id="LT-r-IPSignalling">
                      <Typography variant="h4" className={classes.titleColor} id="LT-c-IPSignalling">
                        {config?.Location?.ip === "obfuscate" && config?.options.debug ? obfuscateIp(ip) : ip}
                      </Typography>
                    </div>
                    <div className={classes.result} id="LT-r-citySignalling">
                      <Typography variant="h4" className={classes.titleColor} id="LT-c-citySignalling">
                        {city}
                      </Typography>
                    </div>
                    <div className={classes.result} id="LT-r-countrySignalling">
                      <Typography variant="h4" className={classes.titleColor} id="LT-c-countrySignalling">
                        {country}
                      </Typography>
                    </div>
                    <div className={classes.result} id="LT-r-orgSignalling">
                      <Typography variant="h4" className={classes.titleColor} id="LT-c-orgSignalling">
                        {organization}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.resultWrapper}>
              <div className={classes.resultContainer}>
                <div className={classes.result} id="LT-r-IP">
                  <Typography variant="caption" className={classes.label}>
                    IP
                  </Typography>
                  <Typography variant="h4" className={classes.titleColor} id="LT-c-IP">
                    {config?.Location?.ip === "obfuscate" && config?.options.debug ? obfuscateIp(ip) : ip}
                  </Typography>
                </div>
                <div className={classes.result} id="LT-r-city">
                  <Typography variant="caption" className={classes.label}>
                    {t("city")}
                  </Typography>
                  <Typography variant="h4" className={classes.titleColor} id="LT-c-city">
                    {city}
                  </Typography>
                </div>
                <div className={classes.result} id="LT-r-country">
                  <Typography variant="caption" className={classes.label}>
                    {t("country")}
                  </Typography>
                  <Typography variant="h4" className={classes.titleColor} id="LT-c-country">
                    {country}
                  </Typography>
                </div>
                <div className={classes.result} id="LT-r-org">
                  <Typography variant="caption" className={classes.label}>
                    {t("org")}
                  </Typography>
                  <Typography variant="h4" className={classes.titleColor} id="LT-c-org">
                    {organization}
                  </Typography>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="tests-card-content tests-card-content--min tst-location">
          <table>
            <tbody>
              <tr className="tst-ip" id="LT-r-IP">
                <td>IP</td>
                <td id="LT-c-IP">
                  {
                    <RenderValues
                      {...defaultProps}
                      value={`${config?.Location?.ip === "obfuscate" && config?.options.debug ? obfuscateIp(ip) : ip} ${
                        isVPN ? "<span>VPN</span>" : isHosted ? "<span>HOSTED</span>" : ""
                      }`}
                      color={ipColor}
                    />
                  }
                </td>
              </tr>
              <tr className="tst-city" id="LT-r-city">
                <td>{t("city")}</td>
                <td id="LT-c-city">{<RenderValues value={city} {...defaultProps} />}</td>
              </tr>
              <tr className="tst-country" id="LT-r-country">
                <td>{t("country")}</td>
                <td id="LT-c-country">{<RenderValues value={country} {...defaultProps} />}</td>
              </tr>
              <tr className="tst-org" id="LT-r-org">
                <td>{t("org")}.</td>
                <td id="LT-c-org">{<RenderValues value={organization} {...defaultProps} />}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </TestHeader>
  );
};

export default withTheme()(withStyles(styles)(LocationTest));
