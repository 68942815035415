import React from "react";
import { useTranslation } from "react-i18next";
import withTheme from "@material-ui/core/styles/withTheme";
import TestHeader from "../TestHeader";
import { ThroughputIco } from "../../icons";
import { RenderValues } from "../helpers/utils";
import { IColorThresholds } from "twillio-tests/core/testConfiguration";
import { formatNumber, getColorValue } from "../../../helpers/utils";
import { IThroughputTest, ITestsResult } from "twillio-tests/core/TestResults";
import ResultRow from "./UI/ResultRow";
import { withStyles } from "@material-ui/core";

interface IThroughputTestProps {
  mainColor?: string;
  testResult?: ITestsResult;
  theme?: any;
  colorThresholds?: IColorThresholds;
  knowledgeUrl?: string;
  estimateTime?: number;
  testName?: string;
  config: any;
  classes?: any;
}

const initialValues: IThroughputTest = {
  averageBandwidth: undefined,
  concurrentConnections: undefined,
  highestBandwidth: undefined,
  lowestBandwidth: undefined,
  maxThroughputs: undefined,
  minThroughputs: undefined,
};

const styles = (theme: any): any => ({
  testContainer: {
    display: "flex",
    flex: 1,
    gap: "44px",
    alignItems: "center",
    justifyContent: "center",
    padding: "16px",
  },
  barContainer: {
    display: "flex",
    flex: 1,
    gap: "16px",
    justifyContent: "end",
    alignItems: "end",
    height: "100%",
  },
  resultContainer: {
    display: "flex",
    flexDirection: "column",
    "& > :not(:first-child)": {
      borderTopWidth: 1,
      borderBottomWidth: 0,
      borderTop: `1px solid ${theme.newPalette.other.divider}`,
    },
  },
  bar: {
    width: "24px",
    maxWidth: "24px",
    borderRadius: "999px 999px 0 0",
  },
});

const ThroughputTest = (props: IThroughputTestProps) => {
  const { mainColor, testResult, theme, colorThresholds, knowledgeUrl, estimateTime, testName, config, classes } = props;
  const throughputTest = (testResult && (testResult as any)?.bandwidthTest) || testResult?.throughput || initialValues;
  const currentThresholds = colorThresholds?.Throughput || null;
  const { error, averageBandwidth, concurrentConnections, highestBandwidth, lowestBandwidth, maxThroughputs, minThroughputs } = throughputTest;

  const defaultProps = {
    mainColor,
    color: config?.useNewWidgetDesign ? theme.newPalette.text.primary : theme.customColors.successColor,
    formatting: formatNumber,
  };

  const colorTypes = {
    errorColor: config?.useNewWidgetDesign ? theme.newPalette.error.main : theme.customColors.errorColor,
    warningColor: config?.useNewWidgetDesign ? theme.newPalette.warning.main : theme.customColors.warningColor,
    successColor: config?.useNewWidgetDesign ? theme.newPalette.text.primary : theme.customColors.successColor,
  };
  const showSessions = config.Throughput?.codec?.toLowerCase() !== "none";
  const formatting = !showSessions
    ? (value: number | string) => `${formatNumber(Number(value))} <span>kbps</span>`
    : (value: number | string) => formatNumber(Number(value));
  const { t } = useTranslation(["common", "tests"]);

  const getBarHeight = (value: any, max: any): any => {
    return `${(value / max) * 100}%`;
  };

  return (
    <TestHeader
      icon={<ThroughputIco />}
      title={t("tests:throughput.title")}
      tooltip={t("tests:throughput.tooltip")}
      error={error}
      knowledgeUrl={knowledgeUrl}
      estimateTime={estimateTime}
      testName={testName}
      //badge={testResult?.testCall?.transportProtocol}
    >
      {config?.useNewWidgetDesign ? (
        <div className={classes.testContainer}>
          <div className={classes.barContainer}>
            <div
              className={classes.bar}
              style={{
                height: getBarHeight(lowestBandwidth, highestBandwidth),
                backgroundColor: currentThresholds?.bitrate
                  ? getColorValue(
                      {
                        ...colorTypes,
                        value: lowestBandwidth,
                        warningValue: currentThresholds.bitrate.warning,
                        errorValue: currentThresholds.bitrate.error,
                      },
                      currentThresholds.bitrate.isLess
                    )
                  : defaultProps.color,
              }}
            />
            <div
              className={classes.bar}
              style={{
                height: getBarHeight(averageBandwidth, highestBandwidth),
                backgroundColor: currentThresholds?.bitrate
                  ? getColorValue(
                      {
                        ...colorTypes,
                        value: averageBandwidth,
                        warningValue: currentThresholds.bitrate.warning,
                        errorValue: currentThresholds.bitrate.error,
                      },
                      currentThresholds.bitrate.isLess
                    )
                  : defaultProps.color,
              }}
            />
            <div
              className={classes.bar}
              style={{
                height: getBarHeight(highestBandwidth, highestBandwidth),
                backgroundColor: currentThresholds?.bitrate
                  ? getColorValue(
                      {
                        ...colorTypes,
                        value: highestBandwidth,
                        warningValue: currentThresholds.bitrate.warning,
                        errorValue: currentThresholds.bitrate.error,
                      },
                      currentThresholds.bitrate.isLess
                    )
                  : defaultProps.color,
              }}
            />
          </div>
          <div className={classes.resultContainer}>
            <ResultRow
              variant="with-title"
              title={t("minimum")}
              value={lowestBandwidth}
              sessionValue={minThroughputs}
              bitrateColor={
                currentThresholds?.bitrate
                  ? getColorValue(
                      {
                        ...colorTypes,
                        value: lowestBandwidth,
                        warningValue: currentThresholds.bitrate.warning,
                        errorValue: currentThresholds.bitrate.error,
                      },
                      currentThresholds.bitrate.isLess
                    )
                  : defaultProps.color
              }
              color={
                currentThresholds?.bitrate
                  ? getColorValue(
                      {
                        ...colorTypes,
                        value: lowestBandwidth,
                        warningValue: currentThresholds.bitrate.warning,
                        errorValue: currentThresholds.bitrate.error,
                      },
                      currentThresholds.bitrate.isLess
                    )
                  : defaultProps.color
              }
              id="TT-r-minimum"
              valueId="TT-c-lowestBandwidth"
              sessionId="TT-c-minThroughputs"
              tstId="tst-min-kbps"
              valueTstId="tst-min-sessions"
            />
            <ResultRow
              variant="with-title"
              title={t("average")}
              value={averageBandwidth}
              sessionValue={concurrentConnections}
              color={
                currentThresholds?.bitrate
                  ? getColorValue(
                      {
                        ...colorTypes,
                        value: averageBandwidth,
                        warningValue: currentThresholds.bitrate.warning,
                        errorValue: currentThresholds.bitrate.error,
                      },
                      currentThresholds.bitrate.isLess
                    )
                  : defaultProps.color
              }
              bitrateColor={
                currentThresholds?.bitrate
                  ? getColorValue(
                      {
                        ...colorTypes,
                        value: averageBandwidth,
                        warningValue: currentThresholds.bitrate.warning,
                        errorValue: currentThresholds.bitrate.error,
                      },
                      currentThresholds.bitrate.isLess
                    )
                  : defaultProps.color
              }
              id="TT-r-average"
              valueId="TT-c-averageBandwidth"
              sessionId="TT-c-concurrentConnections"
              tstId="tst-avg-kbps"
              valueTstId="tst-avg-sessions"
            />
            <ResultRow
              variant="with-title"
              title={t("maximum")}
              value={highestBandwidth}
              sessionValue={maxThroughputs}
              color={
                currentThresholds?.bitrate
                  ? getColorValue(
                      {
                        ...colorTypes,
                        value: highestBandwidth,
                        warningValue: currentThresholds.bitrate.warning,
                        errorValue: currentThresholds.bitrate.error,
                      },
                      currentThresholds.bitrate.isLess
                    )
                  : defaultProps.color
              }
              bitrateColor={
                currentThresholds?.bitrate
                  ? getColorValue(
                      {
                        ...colorTypes,
                        value: highestBandwidth,
                        warningValue: currentThresholds.bitrate.warning,
                        errorValue: currentThresholds.bitrate.error,
                      },
                      currentThresholds.bitrate.isLess
                    )
                  : defaultProps.color
              }
              id="TT-r-highestBandwidth"
              valueId="TT-c-highestBandwidth"
              sessionId="TT-c-maxThroughputs"
              tstId="tst-max-kbps"
              valueTstId="tst-max-sessions"
            />
          </div>
        </div>
      ) : (
        <div className="tests-card-content tests-card-content--throughput tst-throughput">
          <table>
            {showSessions && (
              <thead>
                <tr>
                  <th />
                  <th>kbps</th>
                  <th>{t("session", { count: 0 })}</th>
                </tr>
              </thead>
            )}
            <tbody>
              <tr id="TT-r-highestBandwidth">
                <td>{t("maximum")}</td>
                <td id="TT-c-highestBandwidth">
                  <RenderValues
                    {...defaultProps}
                    tstId="tst-max-kbps"
                    value={highestBandwidth}
                    formatting={formatting}
                    color={
                      currentThresholds?.bitrate
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: highestBandwidth,
                              warningValue: currentThresholds.bitrate.warning,
                              errorValue: currentThresholds.bitrate.error,
                            },
                            currentThresholds.bitrate.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
                {showSessions && (
                  <td id="TT-c-maxThroughputs">
                    <RenderValues
                      {...defaultProps}
                      tstId="tst-max-sessions"
                      value={maxThroughputs}
                      color={
                        currentThresholds?.maxThroughputs
                          ? getColorValue(
                              {
                                ...colorTypes,
                                value: maxThroughputs,
                                warningValue: currentThresholds.maxThroughputs.warning,
                                errorValue: currentThresholds.maxThroughputs.error,
                              },
                              currentThresholds.maxThroughputs.isLess
                            )
                          : defaultProps.color
                      }
                    />
                  </td>
                )}
              </tr>
              <tr id="TT-r-average">
                <td>{t("average")}</td>
                <td id="TT-c-averageBandwidth">
                  <RenderValues
                    {...defaultProps}
                    tstId="tst-avg-kbps"
                    value={averageBandwidth}
                    formatting={formatting}
                    color={
                      currentThresholds?.bitrate
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: averageBandwidth,
                              warningValue: currentThresholds.bitrate.warning,
                              errorValue: currentThresholds.bitrate.error,
                            },
                            currentThresholds.bitrate.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
                {showSessions && (
                  <td id="TT-c-concurrentConnections">
                    <RenderValues
                      {...defaultProps}
                      tstId="tst-avg-sessions"
                      value={concurrentConnections}
                      color={
                        currentThresholds?.concurrentConnections
                          ? getColorValue(
                              {
                                ...colorTypes,
                                value: concurrentConnections,
                                warningValue: currentThresholds.concurrentConnections.warning,
                                errorValue: currentThresholds.concurrentConnections.error,
                              },
                              currentThresholds.concurrentConnections.isLess
                            )
                          : defaultProps.color
                      }
                    />
                  </td>
                )}
              </tr>
              <tr id="TT-r-minimum">
                <td>{t("minimum")}</td>
                <td id="TT-c-lowestBandwidth">
                  <RenderValues
                    {...defaultProps}
                    tstId="tst-min-kbps"
                    value={lowestBandwidth}
                    formatting={formatting}
                    color={
                      currentThresholds?.bitrate
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: lowestBandwidth,
                              warningValue: currentThresholds.bitrate.warning,
                              errorValue: currentThresholds.bitrate.error,
                            },
                            currentThresholds.bitrate.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
                {showSessions && (
                  <td id="TT-c-minThroughputs">
                    <RenderValues
                      {...defaultProps}
                      tstId="tst-min-sessions"
                      value={minThroughputs}
                      color={
                        currentThresholds?.minThroughputs
                          ? getColorValue(
                              {
                                ...colorTypes,
                                value: minThroughputs,
                                warningValue: currentThresholds.minThroughputs.warning,
                                errorValue: currentThresholds.minThroughputs.error,
                              },
                              currentThresholds.minThroughputs.isLess
                            )
                          : defaultProps.color
                      }
                    />
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </TestHeader>
  );
};

export default withTheme()(withStyles(styles)(ThroughputTest));
