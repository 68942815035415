import React from "react";
import { useTranslation } from "react-i18next";
import withTheme from "@material-ui/core/styles/withTheme";
import TestHeader from "../TestHeader";
import { DnsLookupIco } from "../../icons";
import { RenderValues } from "../helpers/utils";
import { IColorThresholds, TestConfiguration } from "twillio-tests/core/testConfiguration";
import { formatNumber, getColorValue } from "../../../helpers/utils";
import { IDNSLookup, ITestsResult } from "twillio-tests/core/TestResults";
import { Typography, withStyles } from "@material-ui/core";
import SlicedDonutChart from "./NewTests/UI/SlicedDonutChart";
import ResultRow from "./UI/ResultRow";

interface IDNSLookupTestProps {
  mainColor?: string;
  testResult?: ITestsResult;
  theme?: any;
  colorThresholds?: IColorThresholds;
  knowledgeUrl?: string;
  estimateTime?: number;
  testName?: string;
  currentTheme?: string;
  classes?: any;
  config?: TestConfiguration;
  logs?: any;
}

const initialValues = {
  error: undefined,
  totalConnections: undefined,
  successfulConnections: undefined,
  averageConnectionTime: undefined,
  highestConnectionTime: undefined,
  shortestConnectionTime: undefined,
};

const styles = (theme: any): any => ({
  testContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    gap: "16px",
    justifyContent: "center",
    padding: "16px",
  },
  resultWrapper: {
    display: "flex",
    flex: 1,
    width: "100%",
  },
  svgContainer: {
    display: "flex",
    flex: 1,
    height: "100%",
  },
  resultContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    gap: "20px",
  },
  results: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    "& > :not(:first-child)": {
      borderTopWidth: 1,
      borderBottomWidth: 0,
      borderTop: `1px solid ${theme.newPalette.other.divider}`,
    },
  },
  titleColor: {
    color: theme.newPalette.text.primary,
    textTransform: "capitalize",
  },
  failedDomainSection: {
    display: "flex",
    flexDirection: "column",
    fontSize: "12px",
    lineHeight: "14.4px",
    letterSpacing: "0.4px",
  },
  failedDomainText: {
    color: theme.newPalette.text.primary,
  },
  failedDomain: {
    color: theme.newPalette.error.main,
  },
});

const DNSLookupTest = (props: IDNSLookupTestProps) => {
  const { mainColor, testResult, theme, knowledgeUrl, estimateTime, colorThresholds, testName, currentTheme, classes, config, logs } = props;
  const dnsLookup: IDNSLookup = testResult && testResult.dnsLookup ? testResult.dnsLookup : initialValues;
  const currentThresholds = colorThresholds?.DNSLookup || null;
  const { error, meta, totalConnections, successfulConnections, averageConnectionTime, highestConnectionTime, shortestConnectionTime } = dnsLookup;
  const defaultProps = {
    mainColor,
    color: config?.useNewWidgetDesign ? theme.newPalette.text.primary : theme.customColors.successColor,
    formatting: (value: number) => `${formatNumber(value)} <span>ms</span>`,
  };

  const colorTypes = {
    errorColor: config?.useNewWidgetDesign ? theme.newPalette.error.main : theme.customColors.errorColor,
    warningColor: config?.useNewWidgetDesign ? theme.newPalette.warning.main : theme.customColors.warningColor,
    successColor: config?.useNewWidgetDesign ? theme.newPalette.text.primary : theme.customColors.successColor,
  };

  const { t } = useTranslation(["common", "tests"]);
  const title = currentTheme === "nice" ? t("tests:dns.titleForNice") : t("tests:dns.title");

  const findBlockedDomain = (logs: any[], testResult?: ITestsResult) => {
    if (testResult) {
      if (testResult.dnsLookup?.failedDomains?.length) {
        return testResult.dnsLookup?.failedDomains[0].domain;
      }
    }
    // fallback method finding in logs
    for (const log of logs) {
      if (typeof log.message === "string" && log.message.includes("blocked: Blocked")) {
        const noDomainStr = log.message.replace("domain: ", "");
        const nextComma = noDomainStr.indexOf(",");
        if (nextComma) {
          return noDomainStr.slice(0, nextComma);
        }
      }
    }
    return undefined;
  };

  const blockedDomain = findBlockedDomain(logs, testResult);

  return (
    <TestHeader
      icon={<DnsLookupIco />}
      title={title}
      tooltip={t("tests:dns.title")}
      error={error}
      knowledgeUrl={knowledgeUrl}
      estimateTime={estimateTime}
      errorsCount={meta?.errorsCount}
      testName={testName}
    >
      {config?.useNewWidgetDesign ? (
        <div className={classes.testContainer}>
          <div className={classes.resultWrapper}>
            <div className={classes.svgContainer}>
              {totalConnections !== undefined && successfulConnections !== undefined && (
                <SlicedDonutChart
                  x={totalConnections}
                  y={totalConnections - successfulConnections}
                  parentId="DNSL-r-connected"
                  childId="DNSL-c-connected"
                  themeColor={colorTypes}
                />
              )}
            </div>
            <div className={classes.resultContainer}>
              <Typography variant="body1" className={classes.titleColor}>
                {t("connectionTime")}
              </Typography>
              <div className={classes.results}>
                <ResultRow
                  label={t("average")}
                  value={averageConnectionTime}
                  color={
                    currentThresholds && currentThresholds.averageConnectionTime
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: averageConnectionTime,
                            warningValue: currentThresholds.averageConnectionTime.warning,
                            errorValue: currentThresholds.averageConnectionTime.error,
                            warningValueMax: currentThresholds.averageConnectionTime.warningMax,
                            warningValueMin: currentThresholds.averageConnectionTime.warningMin,
                          },
                          currentThresholds.averageConnectionTime.isLess
                        )
                      : defaultProps.color
                  }
                  unit="ms"
                  id="DNSL-r-average"
                  valueId="DNSL-c-average"
                />
                <ResultRow
                  label={t("longest")}
                  value={highestConnectionTime}
                  color={
                    currentThresholds && currentThresholds.highestConnectionTime
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: highestConnectionTime,
                            warningValue: currentThresholds.highestConnectionTime.warning,
                            errorValue: currentThresholds.highestConnectionTime.error,
                            warningValueMax: currentThresholds.highestConnectionTime.warningMax,
                            warningValueMin: currentThresholds.highestConnectionTime.warningMin,
                          },
                          currentThresholds.highestConnectionTime.isLess
                        )
                      : defaultProps.color
                  }
                  unit="ms"
                  id="DNSL-r-highest"
                  valueId="DNSL-c-highest"
                />
                <ResultRow
                  label={t("shortest")}
                  value={shortestConnectionTime}
                  color={
                    currentThresholds && currentThresholds.shortestConnectionTime
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: shortestConnectionTime,
                            warningValue: currentThresholds.shortestConnectionTime.warning,
                            errorValue: currentThresholds.shortestConnectionTime.error,
                            warningValueMax: currentThresholds.shortestConnectionTime.warningMax,
                            warningValueMin: currentThresholds.shortestConnectionTime.warningMin,
                          },
                          currentThresholds.shortestConnectionTime.isLess
                        )
                      : defaultProps.color
                  }
                  unit="ms"
                  id="DNSL-r-shortest"
                  valueId="DNSL-c-shortest"
                />
              </div>
            </div>
          </div>
          {blockedDomain && (
            <div className={classes.failedDomainSection}>
              <span className={classes.failedDomainText}>First failed domain:</span>
              <span className={classes.failedDomain}>{blockedDomain}</span>
            </div>
          )}
        </div>
      ) : (
        <div className="tests-card-content tests-card-content--min tst-dns-lookup">
          <table>
            <tbody>
              <tr className="tst-connected" id="DNSL-r-connected">
                <td>{t("connected")}</td>
                <td id="DNSL-c-connected">
                  <RenderValues
                    {...defaultProps}
                    value={successfulConnections}
                    formatting={(value: number) => `${value} of ${totalConnections}`}
                    color={successfulConnections === totalConnections ? defaultProps.color : colorTypes.errorColor}
                  />
                </td>
              </tr>
              <tr className="tst-average-connection-time" id="DNSL-r-average">
                <td>
                  {t("average")} {t("connectionTime")}
                </td>
                <td id="DNSL-c-average">
                  <RenderValues
                    {...defaultProps}
                    value={averageConnectionTime}
                    color={
                      currentThresholds && currentThresholds.averageConnectionTime
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: averageConnectionTime,
                              warningValue: currentThresholds.averageConnectionTime.warning,
                              errorValue: currentThresholds.averageConnectionTime.error,
                              warningValueMax: currentThresholds.averageConnectionTime.warningMax,
                              warningValueMin: currentThresholds.averageConnectionTime.warningMin,
                            },
                            currentThresholds.averageConnectionTime.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
              </tr>
              <tr className="tst-highest-connection-time" id="DNSL-r-highest">
                <td>
                  {t("highest")} {t("connectionTime")}
                </td>
                <td id="DNSL-c-highest">
                  <RenderValues
                    {...defaultProps}
                    value={highestConnectionTime}
                    color={
                      currentThresholds && currentThresholds.highestConnectionTime
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: highestConnectionTime,
                              warningValue: currentThresholds.highestConnectionTime.warning,
                              errorValue: currentThresholds.highestConnectionTime.error,
                              warningValueMax: currentThresholds.highestConnectionTime.warningMax,
                              warningValueMin: currentThresholds.highestConnectionTime.warningMin,
                            },
                            currentThresholds.highestConnectionTime.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
              </tr>
              <tr className="tst-shortest-connection-time" id="DNSL-r-shortest">
                <td>
                  {t("shortest")} {t("connectionTime")}
                </td>
                <td id="DNSL-c-shortest">
                  <RenderValues
                    {...defaultProps}
                    value={shortestConnectionTime}
                    color={
                      currentThresholds && currentThresholds.shortestConnectionTime
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: shortestConnectionTime,
                              warningValue: currentThresholds.shortestConnectionTime.warning,
                              errorValue: currentThresholds.shortestConnectionTime.error,
                              warningValueMax: currentThresholds.shortestConnectionTime.warningMax,
                              warningValueMin: currentThresholds.shortestConnectionTime.warningMin,
                            },
                            currentThresholds.shortestConnectionTime.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </TestHeader>
  );
};

export default withTheme()(withStyles(styles)(DNSLookupTest));
