import React from "react";
import { RerunIcon } from "../../assets/icons";
import { useTranslation } from "react-i18next";
interface Props {
  testReload: () => boolean | Promise<unknown>;
  classes?: any;
  createDate?: string;
}

const TestReloadButton = ({ testReload, classes, createDate }: Props) => {
  const { t } = useTranslation(["tests"]);

  const twentyFourHours = 24 * 60 * 60 * 1000;
  const currentDate = new Date();
  const isExpired = createDate && currentDate.getTime() - new Date(createDate).getTime() > twentyFourHours;

  return (
    <button
      className={`${classes.btnOverride} ${classes.showBtn} hide-for-image`}
      style={{
        marginTop: "32px",
        opacity: isExpired ? "0" : "100",
        pointerEvents: isExpired ? "none" : "auto",
      }}
      onClick={() => testReload()}
      data-html2canvas-ignore
      id="test-reload"
    >
      <RerunIcon />
      <span>{t("tests:resultPage.rerun")}</span>
    </button>
  );
};

export default TestReloadButton;
